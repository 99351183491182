import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/system";

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "'Quicksand', sans-serif",
  color: "#4A4A4A",
}));

const Highlight = styled("span")(({ color }) => ({
  color,
  fontWeight: "bold",
}));

const FAQ: React.FC = () => {
  return (
    <Box sx={{ padding: 3, backgroundColor: "#FAFAFA", borderRadius: 2 }}>
      <CustomTypography variant="h4" gutterBottom sx={{ color: "#2C3E50" }}>
        Information
      </CustomTypography>
      <CustomTypography paragraph>
        Välkommen till vår bettingsida! Här kan du tävla mot dina vänner i en
        rolig och spännande bettingupplevelse, helt gratis och utan koppling
        till några spelbolag. Låt oss ta en titt på hur det fungerar och vilka
        regler som gäller.
      </CustomTypography>
      <CustomTypography variant="h6" sx={{ color: "#2C3E50" }}>
        Hur fungerar bettingen?
      </CustomTypography>
      <CustomTypography paragraph>
        - Du tippar på ett resultat i en match. Om ditt lag vinner, tilldelas du
        poäng baserat på resultatet.
        <br />
        Det handlar bara om att ha kul och se vem som är bäst på att förutse
        matchresultat! 👑
      </CustomTypography>

      <CustomTypography
        variant="h4"
        gutterBottom
        sx={{ marginTop: 4, color: "#2C3E50" }}
      >
        Vanliga Frågor och Svar
      </CustomTypography>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "#2C3E50" }} />}
        >
          <CustomTypography>Hur fungerar poängsystemet?</CustomTypography>
        </AccordionSummary>
        <AccordionDetails>
          <CustomTypography>
            <Highlight color="#27AE60">3 poäng</Highlight> för rätt vinnare och
            rätt resultat.
            <br />
            <Highlight color="#F39C12">1 poäng</Highlight> för rätt vinnare men
            fel resultat.
            <br />
            <Highlight color="#E74C3C">0 poäng</Highlight> för fel vinnare.
          </CustomTypography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "#2C3E50" }} />}
        >
          <CustomTypography>Kan jag ändra mitt bet?</CustomTypography>
        </AccordionSummary>
        <AccordionDetails>
          <CustomTypography>
            Ja, du kan ändra ditt bet fram till 1 timme innan matchstart. Efter
            det är ditt val låst.
          </CustomTypography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "#2C3E50" }} />}
        >
          <CustomTypography>När kan jag se mina vänners bet?</CustomTypography>
        </AccordionSummary>
        <AccordionDetails>
          <CustomTypography>
            Du kan se dina vänners bet tidigast 1 timme innan matchstart.
          </CustomTypography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "#2C3E50" }} />}
        >
          <CustomTypography>Hur kontaktar jag supporten?</CustomTypography>
        </AccordionSummary>
        <AccordionDetails>
          <CustomTypography>
            Om du behöver hjälp kan du alltid kontakta oss på{" "}
            <a href="mailto:betamigos24@gmail.com" style={{ color: "#2980B9" }}>
              betamigos24@gmail.com
            </a>
            . Vi finns här för att hjälpa dig!
          </CustomTypography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FAQ;
