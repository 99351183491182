// MatchList.tsx
import React from "react";
import { Box } from "@mui/material"; // eller den komponent som passar din setup
import {
  MatchContainer,
  MatchItem,
  TeamName,
  MatchTime,
  ClickableIndicator,
} from "./StyledComponents"; // Anpassa sökvägen efter var du har dina stylade komponenter
import { MatchWithTipsDTO } from "./GroupTypes";

interface MatchListProps {
  todayMatches: MatchWithTipsDTO[];
  handleMatchClick: (match: MatchWithTipsDTO) => void;
}

const MatchList: React.FC<MatchListProps> = ({
  todayMatches,
  handleMatchClick,
}) => {
  const maxLength = 10;

  return (
    <MatchContainer>
      {todayMatches.map((match) => {
        const shortHomeTeamName =
          match.homeTeam && match.homeTeam.length > maxLength
            ? `${match.homeTeam.slice(0, maxLength)}...`
            : match.homeTeam;

        const shortAwayTeamName =
          match.awayTeam && match.awayTeam.length > maxLength
            ? `${match.awayTeam.slice(0, maxLength)}...`
            : match.awayTeam;

        const localTime = new Date(match.matchDate + "Z").toLocaleTimeString(
          [],
          {
            hour: "2-digit",
            minute: "2-digit",
          }
        );

        return (
          <MatchItem key={match.id} onClick={() => handleMatchClick(match)}>
            <TeamName>{shortHomeTeamName}</TeamName>
            {match.homeTeamCrestUrl && (
              <Box
                component="img"
                src={match.homeTeamCrestUrl}
                alt={match.homeTeam || ""}
                sx={{ width: 24, height: 24, mx: 1 }}
              />
            )}
            <MatchTime>{localTime}</MatchTime>
            {match.awayTeamCrestUrl && (
              <Box
                component="img"
                src={match.awayTeamCrestUrl}
                alt={match.awayTeam || ""}
                sx={{ width: 24, height: 24, mx: 1 }}
              />
            )}
            <TeamName>{shortAwayTeamName}</TeamName>
          </MatchItem>
        );
      })}
      <ClickableIndicator>Click on a match to see tips</ClickableIndicator>
    </MatchContainer>
  );
};

export default MatchList;
