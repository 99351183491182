import React, { useState } from "react";
import { Box, Button, Typography, Avatar, useTheme, useMediaQuery } from "@mui/material";
import MatchDetails from "./MatchDetails";
import { MatchProps } from "./ScoreBoardTypes";

const Match: React.FC<MatchProps> = ({ match, tip, onTipChange, liveScore }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);

  const matchDateUtc = new Date(match.matchDate + "Z");
  const nowUTC = new Date();
  const oneHourBeforeMatch = new Date(matchDateUtc.getTime() - 60 * 60 * 1000);
  const disableBetting = nowUTC >= oneHourBeforeMatch;

  const incrementScore = (
    team: "homeTeam" | "awayTeam",
    event: React.MouseEvent
  ) => {
    event.stopPropagation();
    if (disableBetting) return;
    const newScoreHomeTeam =
      team === "homeTeam"
        ? tip
          ? tip.predictedScoreHomeTeam + 1
          : 1
        : tip?.predictedScoreHomeTeam || 0;
    const newScoreAwayTeam =
      team === "awayTeam"
        ? tip
          ? tip.predictedScoreAwayTeam + 1
          : 1
        : tip?.predictedScoreAwayTeam || 0;
    onTipChange(match.matchId, newScoreHomeTeam, newScoreAwayTeam);
  };

  const decrementScore = (
    team: "homeTeam" | "awayTeam",
    event: React.MouseEvent
  ) => {
    event.stopPropagation();
    if (disableBetting) return;
    const newScoreHomeTeam =
      team === "homeTeam" && tip && tip.predictedScoreHomeTeam > 0
        ? tip.predictedScoreHomeTeam - 1
        : tip?.predictedScoreHomeTeam || 0;
    const newScoreAwayTeam =
      team === "awayTeam" && tip && tip.predictedScoreAwayTeam > 0
        ? tip.predictedScoreAwayTeam - 1
        : tip?.predictedScoreAwayTeam || 0;
    onTipChange(match.matchId, newScoreHomeTeam, newScoreAwayTeam);
  };

  let backgroundColor = "";

  if (match.played && tip) {
    if (
      tip.predictedScoreHomeTeam === match.realScoreHomeTeam &&
      tip.predictedScoreAwayTeam === match.realScoreAwayTeam
    ) {
      backgroundColor = "#4CAF50"; // Green
    } else if (
      (tip.predictedScoreHomeTeam > tip.predictedScoreAwayTeam &&
        match.realScoreHomeTeam > match.realScoreAwayTeam) ||
      (tip.predictedScoreHomeTeam < tip.predictedScoreAwayTeam &&
        match.realScoreHomeTeam < match.realScoreAwayTeam) ||
      (tip.predictedScoreHomeTeam === tip.predictedScoreAwayTeam &&
        match.realScoreHomeTeam === match.realScoreAwayTeam)
    ) {
      backgroundColor = "#FFEB3B"; // Yellow
    } else {
      backgroundColor = "#FF5252"; // Red
    }
  }

  const formattedDate = new Date(match.matchDate + "Z").toLocaleDateString(
    "sv-SE",
    {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }
  );

  return (
    <>
      <Box
        my={2}
        p={2}
        borderRadius={4}
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        justifyContent="space-between"
        sx={{
          position: "relative",
          background: `linear-gradient(to bottom, ${backgroundColor}, white)`,
          color: "#000",
          overflow: "hidden",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          zIndex: 0,
        }}
        onClick={() => setOpen(true)}
      >
        <Typography variant="caption" color="textSecondary" mb={1}>
          {formattedDate}
        </Typography>
        <Box display="flex" alignItems="center" flexDirection="row" mb={2}>
          <Avatar
            src={match.homeTeamCrest || undefined}
            alt={match.homeTeam || ""}
            sx={{ mr: 3 }}
          />
          <Typography variant="body2" sx={{ flexGrow: 1 }}>
            {match.homeTeam && match.homeTeam.length > 10
              ? match.homeTeam.slice(0, 7) + '...'
              : match.homeTeam}
          </Typography>
          <Typography variant="body1">
            {tip ? tip.predictedScoreHomeTeam : 0}
          </Typography>

          <Box display="flex" flexDirection="column" alignItems="center" mx={2}>
            
            <Button
              variant="outlined"
              onClick={(event) => incrementScore("homeTeam", event)}
              disabled={disableBetting}
              size="small"
              sx={{ mb: 0.5 }} // Small margin between the buttons
            >
              +
            </Button>
            <Button
              variant="outlined"
              onClick={(event) => decrementScore("homeTeam", event)}
              disabled={disableBetting}
              size="small"
            >
              -
            </Button>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" flexDirection="row">
          <Avatar
            src={match.awayTeamCrest || undefined}
            alt={match.awayTeam || ""}
            sx={{ mr: 3 }}
          />
          <Typography variant="body2" sx={{ flexGrow: 1 }}>
            {match.awayTeam && match.awayTeam.length > 10
              ? match.awayTeam.slice(0, 7) + '...'
              : match.awayTeam}
          </Typography>
          <Typography variant="body1">
            {tip ? tip.predictedScoreAwayTeam : 0}
          </Typography>
          <Box display="flex" flexDirection="column" alignItems="center" mx={2}>
            
            <Button
              variant="outlined"
              onClick={(event) => incrementScore("awayTeam", event)}
              disabled={disableBetting}
              size="small"
              sx={{ mb: 0.5 }}
            >
              +
            </Button>
            <Button
              variant="outlined"
              onClick={(event) => decrementScore("awayTeam", event)}
              disabled={disableBetting}
              size="small"
            >
              -
            </Button>
          </Box>
        </Box>

        {/* Visa resultat */}
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          {match.played && (
            <Typography component="span" variant="h5">
              {match.realScoreHomeTeam} - {match.realScoreAwayTeam}
            </Typography>
          )}
        </Box>

        {liveScore && (
          <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
            <Typography component="span" variant="h5">
              {liveScore.realScoreHomeTeam} - {liveScore.realScoreAwayTeam}
            </Typography>

            {/* Blinkande prick */}
            <Box
              sx={{
                width: 10,
                height: 10,
                backgroundColor: "#4caf50",
                borderRadius: "50%",
                animation: "blink 1s infinite",
                marginLeft: "8px",
                "@keyframes blink": {
                  "0%": { opacity: 1 },
                  "50%": { opacity: 0 },
                  "100%": { opacity: 1 },
                },
              }}
            />
          </Box>
        )}
      </Box>

      <MatchDetails
        open={open}
        handleClose={() => setOpen(false)}
        match={match}
      />
    </>
  );
};

export default Match;
