// theme.ts
import { createTheme } from '@mui/material/styles';

// Skapa ett tema som använder Material Design 3
const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6', // Anpassa till din primära färg
    },
    secondary: {
      main: '#19857b', // Anpassa till din sekundära färg
    },
    error: {
      main: '#red', // Anpassa till din fel-färg
    },
    background: {
      default: '#fff', // Anpassa bakgrundsfärg
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif', // Anpassa typsnitt
  },
  shape: {
    borderRadius: 8, // Anpassa hörnens rundning
  },
  shadows: Array(25).fill('none') as any, // Anpassa skuggor till Material Design 3
});

export default theme;
