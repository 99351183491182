import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import SwipeableViews from "react-swipeable-views";
import { Tabs, Tab } from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import API from "../../components/API";
import { Box, Typography } from "@mui/material";
import {
  UserGroup,
  UserWithPointsDTO,
  UserHistoryDTO,
  TablePositionHistory,
  TipDTO,
  MatchWithTipsDTO,
} from "./GroupTypes";
import { Container, GroupList, ListItemStyled } from "./StyledComponents";
import MatchList from "./MatchList";
import HighscoreList from "./HighscoreList";
import MatchTipsModal from "./MatchTipsModal";

const Groups: React.FC = () => {
  const { token } = useAuth();
  const [userGroups, setUserGroups] = useState<UserGroup[]>([]);
  const [groupUsers, setGroupUsers] = useState<UserWithPointsDTO[]>([]);
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);
  const [tablePositionHistory, setTablePositionHistory] = useState<
    TablePositionHistory[]
  >([]);
  const [userGroupName, setUserGroupName] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<UserWithPointsDTO | null>(
    null
  );
  const [todayMatches, setTodayMatches] = useState<MatchWithTipsDTO[]>([]);
  const [selectedMatchTips, setSelectedMatchTips] = useState<TipDTO[] | null>(
    null
  );
  const [open, setOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [visibleLines, setVisibleLines] = useState(
    groupUsers.map((user) => user.email)
  );
  const [finalScore, setFinalScore] = useState<{
    homeTeam: number;
    awayTeam: number;
  } | null>(null);

  useEffect(() => {
    if (token) {
      const fetchUserGroups = async () => {
        try {
          const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "bypass-tunnel-reminder": "true",
              "Content-Type": "application/json",
            },
          };
          const response = await API.getUserGroupsForUser();
          setUserGroups(response);
        } catch (error) {
          console.error("Error fetching user groups:", error);
        }
      };

      fetchUserGroups();
    }
  }, [token]);

  const handleGroupClick = async (groupId: number) => {
    setSelectedGroupId(groupId);
    try {
      const [usersResponse, historyResponse, matchesResponse] =
        await Promise.all([
          API.getUsersInGroupSortedByPoints(groupId),
          API.getTablePositionHistory(groupId),
          API.getTodayMatchesWithGroupTips(groupId),
        ]);

      const sortedUsers = usersResponse.sort((a, b) => b.points - a.points);
      setGroupUsers(sortedUsers);

      // Sätt visibleLines till alla användar-ID:n när gruppanvändare hämtas
      const userEmails = sortedUsers.map((user) => user.email);
      setVisibleLines(userEmails);

      if (historyResponse) {
        setUserGroupName(historyResponse.userGroupName || "");

        const formattedHistory: TablePositionHistory[] = Object.entries(
          historyResponse.matchNumberToUserPoints
        ).map(([matchNumber, userHistoryDTO]) => ({
          matchNumber: parseInt(matchNumber),
          userHistoryDTO: userHistoryDTO as UserHistoryDTO[],
        }));

        setTablePositionHistory(formattedHistory);
      } else {
        console.error("Invalid history data format", historyResponse);
        setUserGroupName("");
        setTablePositionHistory([]);
        setGroupUsers([]);
      }

      setTodayMatches(matchesResponse);
    } catch (error) {
      console.error(`Error fetching data for group ${groupId}:`, error);
    }
  };

  const handleMatchClick = (match: MatchWithTipsDTO) => {
    const tips = match.tips;
    setSelectedMatchTips(tips);

    if (match != null) {
      if (match.played) {
        // Om matchen är färdigspelad, sätt rätt resultat
        setFinalScore({
          homeTeam: match.realScoreHomeTeam,
          awayTeam: match.realScoreAwayTeam,
        });
      } else {
        // Om matchen inte är färdigspelad, sätt värden som indikerar att det inte finns något resultat
        setFinalScore({
          homeTeam: -1,
          awayTeam: -1,
        });
      }
    }

    setOpen(true);
  };

  const handleUserClick = (user: UserWithPointsDTO) => {
    setSelectedUser(user);
    setOpen(true);
  };

  const handleCloseOverlay = () => {
    setOpen(false);
    setSelectedUser(null);
    setSelectedMatchTips(null);
  };

  const formatDataForChart = () => {
    const data: any[] = [];

    tablePositionHistory.forEach((history) => {
      const matchNumber = history.matchNumber + 1;
      const dataEntry: any = { matchNumber };

      history.userHistoryDTO.forEach((userHistoryDTO) => {
        // Använd email som nyckel
        const userKey = userHistoryDTO.email;

        // Sätt användarens poäng
        if (!dataEntry[userKey]) {
          dataEntry[userKey] = 0;
        }
        dataEntry[userKey] += userHistoryDTO.points;
      });

      data.push(dataEntry);
    });

    return data;
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleTabSwipe = (index: number) => {
    setTabIndex(index);
  };

  const userIdToNameMap: Record<string, string> = Object.fromEntries(
    groupUsers.map((user) => [user.userId, user.name])
  );

  const match =
    selectedMatchTips && selectedMatchTips.length > 0
      ? todayMatches.find((m) => m.matchId === selectedMatchTips[0].matchId)
      : null;

  const handleLegendClick = (payload: any) => {
    const userEmail = payload.value;
    setVisibleLines((prev) =>
      prev.includes(userEmail)
        ? prev.filter((id) => id !== userEmail)
        : [...prev, userEmail]
    );
  };

  return (
    <Container>
      <GroupList>
        <Typography
          variant="body2"
          sx={{ textAlign: "left", color: "#757575", marginBottom: "8px" }}>
          Groups
        </Typography>
        <List>
          {userGroups.map((group) => (
            <ListItemStyled
              key={group.id}
              selected={selectedGroupId === group.id}
              onClick={() => handleGroupClick(group.id)}>
              <ListItemText primary={group.name} />
            </ListItemStyled>
          ))}
        </List>
      </GroupList>
      {selectedGroupId && (
        <>
          <Box>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary">
              <Tab label="Highscore" />
              <Tab label="Today's Matches" />
            </Tabs>
            <SwipeableViews index={tabIndex} onChangeIndex={handleTabSwipe}>
              <HighscoreList
                groupUsers={groupUsers}
                handleUserClick={handleUserClick}
                formatDataForChart={formatDataForChart}
                visibleLines={visibleLines}
                handleLegendClick={handleLegendClick}
              />

              <MatchList
                todayMatches={todayMatches}
                handleMatchClick={handleMatchClick}
              />
            </SwipeableViews>
          </Box>

          <MatchTipsModal
            open={open}
            handleCloseOverlay={handleCloseOverlay}
            match={match}
            finalScore={finalScore}
            selectedMatchTips={selectedMatchTips}
            userIdToNameMap={userIdToNameMap}
          />
        </>
      )}
    </Container>
  );
};

export default Groups;
