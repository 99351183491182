import React, { useState } from "react";
import { styled } from "@mui/system";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import API from "../../components/API";

const Container = styled("div")({
  padding: "24px",
});

const FormControl = styled("div")({
  marginBottom: "16px",
});

const CreateGroup: React.FC = () => {
  const [groupName, setGroupName] = useState("");
  const { token } = useAuth();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupName(e.target.value);
  };

  const handleCreateGroup = async () => {
    try {
      API.createUserGroup(groupName);
      alert("Group created successfully!");
      setGroupName("");

      // Ladda om sidan
      window.location.reload();
    } catch (error) {
      console.error("Error creating group:", error);
      alert("Failed to create group");
    }
  };

  return (
    <Container>
      <Typography variant="h6" sx={{ marginBottom: "1rem" }}>
        Create a New Group
      </Typography>

      <FormControl>
        <TextField
          label="Group Name"
          variant="outlined"
          value={groupName}
          onChange={handleInputChange}
          fullWidth
          style={{ marginBottom: "1rem" }}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleCreateGroup}
        >
          Create Group
        </Button>
      </FormControl>
    </Container>
  );
};

export default CreateGroup;
