import React, { useEffect } from "react";
import { GoogleLogin, CredentialResponse } from "@react-oauth/google";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import API from "../components/API";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { token, setToken } = useAuth();

  const fetchAndSetFirstCompetition = async () => {
    try {
      const competitions = await API.getCompetitions();
      if (competitions.length > 0) {
        const firstCompetition = competitions[0];
        localStorage.setItem("competitionId", firstCompetition.id.toString());
      } else {
        console.log("Ingen tävling hittades.");
      }
    } catch (error) {
      console.error("Fel vid hämtning av tävlingar:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchAndSetFirstCompetition();
      if (token) {
        navigate("/scoreboard");
      }
    };

    fetchData();
  }, [token, navigate]);

  const handleGoogleSuccess = async (response: CredentialResponse) => {
    const code = response.credential;

    try {
      const backendResponse = await API.googleLogin(code);

      const token = backendResponse.token;
      setToken(token);
      localStorage.setItem("token", token); // Spara token i localStorage

      const userName = await API.getUserProfileName();
      localStorage.setItem("userName", userName);
    } catch (error) {
      console.error("Error sending code to backend", error);
    }
  };

  const handleGoogleFailure = () => {
    console.error("Google login failed");
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "10vh", paddingTop: "1vh" }}
    >
      <Grid item xs={12}>
        <GoogleLogin
          onSuccess={handleGoogleSuccess}
          onError={handleGoogleFailure}
        />
      </Grid>
      <Grid item xs={12}>
        <br />
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          style={{ marginTop: "auto", padding: "16px" }}
        >
          Välkommen till vårt betatest! Detta är en kul och kostnadsfri
          bettingsida där vi testar nya funktioner, helt utan koppling till
          några spelbolag. Om du stöter på några problem eller har frågor, hör
          gärna av dig till oss på{" "}
          <a href="mailto:betamigos24@gmail.com">betamigos24@gmail.com</a>.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Login;
