import React, { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { Container, Tabs, Tab, Box } from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import API from "../../components/API";
import {
  findFirstPageWithMatchDate,
  calculateTeamStats,
} from "./ScoreBoardUtils";
import MatchList from "./MatchList";
import LeagueTable from "./LeagueTable";
import { MatchData, Tip, TeamStats } from "./ScoreBoardTypes";

const Scoreboard: React.FC = () => {
  const { token } = useAuth();
  const [matches, setMatches] = useState<MatchData[]>([]);
  const [tips, setTips] = useState<Tip[]>([]);
  const [teamStats, setTeamStats] = useState<TeamStats[]>([]);
  const [mainTabIndex, setMainTabIndex] = useState(0);
  const [leaguePage, setLeaguePage] = useState(0);
  const [saving, setSaving] = useState(false);
  const rowsPerPage = 10;

  useEffect(() => {
    const fetchMatchesAndTips = async () => {
      try {
        const matchesResponse = await API.getAllMatches();
        const sortedMatches = matchesResponse.sort(
          (a: MatchData, b: MatchData) =>
            new Date(a.matchDate).getTime() - new Date(b.matchDate).getTime()
        );
        setMatches(sortedMatches);

        const tipsResponse = await API.getAllTipsForUser();
        setTips(tipsResponse);

        const today = new Date();
        const firstPageWithMatches = findFirstPageWithMatchDate(
          sortedMatches,
          today,
          rowsPerPage
        );
        setLeaguePage(firstPageWithMatches);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchData = async () => {
      await fetchMatchesAndTips();
    };

    fetchData();
  }, [token]);

  useEffect(() => {
    setTeamStats(calculateTeamStats(matches, tips));
  }, [matches, tips]);

  const handleChangeMainTabIndex = (index: number) => {
    setMainTabIndex(index);
  };

  const saveTips = async () => {
    try {
      setSaving(true);
      await API.createOrUpdateTipsForUser(tips);
      alert("Saved tips successfully!");
    } catch (error) {
      console.error("Error saving tips:", error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Container>
      <Tabs
        value={mainTabIndex}
        onChange={(_, newValue) => handleChangeMainTabIndex(newValue)}>
        <Tab label="Matches" />
        <Tab
          label={
            <Box display="flex" flexDirection="column" alignItems="center">
              <span>League Table</span>
              <span style={{ fontSize: "0.8em" }}>based on your betting</span>
            </Box>
          }
        />
      </Tabs>

      <SwipeableViews
        index={mainTabIndex}
        onChangeIndex={handleChangeMainTabIndex}>
        <Box p={3}>
          <MatchList
            matches={matches}
            tips={tips}
            leaguePage={leaguePage}
            rowsPerPage={rowsPerPage}
            setLeaguePage={setLeaguePage}
            setTips={setTips}
            setTeamStats={setTeamStats}
            saveTips={saveTips}
            saving={saving}
          />
        </Box>
        <Box p={3}>
          <LeagueTable teamStats={teamStats} />
        </Box>
      </SwipeableViews>
    </Container>
  );
};

export default Scoreboard;
