// MatchTipsModal.tsx
import React from "react";
import { Modal, Typography } from "@mui/material"; // eller den komponent som passar din setup
import {
  ModalContent,
  ModalHeader,
  RowItem,
  TipsContainer,
} from "./StyledComponents"; // Justera sökvägen
import { TipDTO } from "./GroupTypes";

interface MatchTipsModalProps {
  open: boolean;
  handleCloseOverlay: () => void;
  match: any; // Anpassa till rätt typ för match
  finalScore: { homeTeam: number; awayTeam: number } | null; // Typ av finalScore
  selectedMatchTips: TipDTO[] | null; // Anpassa till din tip-typ
  userIdToNameMap: { [key: string]: string }; // Typ av userId till namn-kartläggning
}

const MatchTipsModal: React.FC<MatchTipsModalProps> = ({
  open,
  handleCloseOverlay,
  match,
  finalScore,
  selectedMatchTips,
  userIdToNameMap,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleCloseOverlay}
      aria-labelledby="match-tips-modal"
      aria-describedby="match-tips-modal-description">
      <ModalContent>
        {match ? (
          <>
            <ModalHeader>
              {match.homeTeamCrestUrl && (
                <img
                  src={match.homeTeamCrestUrl}
                  alt={match.homeTeam || ""}
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "8px",
                  }}
                />
              )}
              vs
              {match.awayTeamCrestUrl && (
                <img
                  src={match.awayTeamCrestUrl}
                  alt={match.awayTeam || ""}
                  style={{
                    width: "24px",
                    height: "24px",
                    marginLeft: "8px",
                  }}
                />
              )}
            </ModalHeader>
            <RowItem>
              <Typography variant="body1">Final Score:</Typography>
              <Typography variant="body1">
                {finalScore &&
                finalScore.homeTeam !== -1 &&
                finalScore.awayTeam !== -1
                  ? `${finalScore.homeTeam} - ${finalScore.awayTeam}`
                  : "N/A"}
              </Typography>
            </RowItem>
            <TipsContainer>
              {selectedMatchTips?.map((tip, index) => {
                const isMatchFinished =
                  finalScore &&
                  finalScore.homeTeam !== -1 &&
                  finalScore.awayTeam !== -1;

                let backgroundColor;
                if (isMatchFinished) {
                  const isTipDraw = tip.homeTeamScore === tip.awayTeamScore;
                  const isFinalScoreDraw =
                    finalScore.homeTeam === finalScore.awayTeam;

                  if (
                    tip.homeTeamScore === finalScore.homeTeam &&
                    tip.awayTeamScore === finalScore.awayTeam
                  ) {
                    backgroundColor = "#4CAF50"; // Exact match
                  } else if (isTipDraw && isFinalScoreDraw) {
                    backgroundColor = "#FFEB3B"; // Tip for draw, but different scores
                  } else if (
                    (tip.homeTeamScore > tip.awayTeamScore &&
                      finalScore.homeTeam > finalScore.awayTeam) ||
                    (tip.homeTeamScore < tip.awayTeamScore &&
                      finalScore.homeTeam < finalScore.awayTeam)
                  ) {
                    backgroundColor = "#FFEB3B"; // Correct winner, wrong score
                  } else {
                    backgroundColor = "#FF5252"; // Completely wrong
                  }
                } else {
                  backgroundColor = "white"; // Set white background if the match is not finished
                }

                return (
                  <RowItem
                    key={index}
                    my={1}
                    p={1}
                    borderRadius={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      position: "relative",
                      background: `linear-gradient(to bottom, ${backgroundColor}, white)`,
                      color: "#000",
                      overflow: "hidden",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      zIndex: 0,
                    }}>
                    <Typography
                      variant="body1"
                      style={{ flex: 1, textAlign: "left" }}>
                      {userIdToNameMap[tip.userId]}
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{ flex: 1, textAlign: "right" }}>
                      {tip.homeTeamScore} - {tip.awayTeamScore}
                    </Typography>
                  </RowItem>
                );
              })}
            </TipsContainer>
          </>
        ) : (
          <Typography variant="body1" align="center" sx={{ mt: 2 }}>
            Group members' tips will be available no earlier than 1 hour before
            the start of the match.
          </Typography>
        )}
      </ModalContent>
    </Modal>
  );
};

export default MatchTipsModal;
