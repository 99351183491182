// StyledComponents.tsx
import styled from "@mui/material/styles/styled";
import { Box, Paper, Typography, ListItemButton } from "@mui/material";

// Container for the main layout
export const Container = styled("div")({
  padding: "24px",
  display: "flex",
  flexDirection: "column",
  gap: "32px",
});

// Group list styled component
export const GroupList = styled(Paper)({
  padding: "16px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
});

// List item styled component
export const ListItemStyled = styled(ListItemButton)<{ selected?: boolean }>(
  ({ selected }) => ({
    cursor: "pointer",
    backgroundColor: selected ? "#e3f2fd" : "transparent",
    "&:hover": {
      backgroundColor: selected ? "#bbdefb" : "#f5f5f5",
    },
    borderRadius: "4px",
    marginBottom: "8px",
  })
);

// Highscore container styled component
export const HighscoreContainer = styled(Paper)({
  padding: "16px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
});

// Highscore item styled component
export const HighscoreItem = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  padding: "12px",
  borderRadius: "4px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#f1f1f1",
  },
});

// Highscore name styled component
export const HighscoreName = styled(Typography)({
  fontWeight: "bold",
});

// Highscore points styled component
export const HighscorePoints = styled(Typography)({
  color: "#757575",
});

// Match container styled component
export const MatchContainer = styled(Paper)({
  padding: "16px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
});

// Match item styled component
export const MatchItem = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "12px",
  borderRadius: "4px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#f1f1f1",
  },
});

// Team name styled component
export const TeamName = styled(Typography)({
  flex: 1,
  textAlign: "center",
  maxWidth: "120px", // Adjust to ensure symmetry
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

// Match time styled component
export const MatchTime = styled(Typography)({
  margin: "0 16px", // Provide space for time between teams
  textAlign: "center",
  fontWeight: "bold",
});

// Clickable indicator styled component
export const ClickableIndicator = styled(Typography)({
  fontStyle: "italic",
  color: "#757575",
  marginTop: "8px",
});

// Modal content styled component
export const ModalContent = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px", // Set the width for the modal
  maxWidth: "90%", // Ensure the modal doesn't exceed the screen width
  backgroundColor: "white",
  border: "2px solid #000",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Add shadow
  padding: "16px",
  borderRadius: "8px",
});

// Modal header styled component
export const ModalHeader = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginBottom: "16px",
});

// Row item styled component
export const RowItem = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "4px 0", // Reduce padding for a thinner height
});

// Tips container styled component
export const TipsContainer = styled(Paper)({
  padding: "16px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
});

// Color palette for graphs or other elements
export const colorPalette = [
  "#8884d8",
  "#82ca9d",
  "#ffc658",
  "#ff7f50",
  "#87ceeb",
  "#da70d6",
  "#32cd32",
  "#6495ed",
  "#ff69b4",
  "#ba55d3",
  "#cd5c5c",
  "#ffa07a",
  "#20b2aa",
  "#778899",
  "#b0c4de",
  "#ff6347",
  "#4682b4",
  "#d2691e",
  "#8b4513",
  "#6a5acd",
];

export const GroupListStyled = styled(Paper)({
  padding: "16px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
});
