import React from "react";
import { Box, Typography, Container, Link, IconButton } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import { useTheme } from "@mui/material/styles";

const Footer: React.FC = () => {
  const theme = useTheme(); // Använd temat

  return (
    <Box
      component="footer"
      sx={{
        bgcolor: "background.paper",
        p: 3,
        position: "relative",
        bottom: 0,
        width: "100%",
        textAlign: "center",
        background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`, // Använd temats primära och sekundära färger
        color: theme.palette.primary.contrastText, // Använd kontrastfärgen för att säkerställa textens synlighet
      }}
    >
      <Container maxWidth="md">
        <Typography variant="body1">
          © {new Date().getFullYear()} Betamigos
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Link href="/faq" color="inherit" underline="hover">
            FAQ
          </Link>
          {" | "}
          <IconButton
            component="a"
            href="mailto:betamigos24@gmail.com"
            color="inherit"
            aria-label="Send email"
          >
            <MailIcon />
          </IconButton>
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
