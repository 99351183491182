import axios from "axios";
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

interface UserWithPointsDTO {
  userId: number;
  name: string;
  email: string;
  points: number;
  competitionId: number;
}

interface UserGroup {
  id: number;
  name: string;
}

interface Competition {
  id: number;
  name: string;
  emblem: string;
}

const getHeaders = () => ({
  Authorization: `Bearer ${localStorage.getItem("token")}`,
  "Content-Type": "application/json",
});

const getCompetitionId = (): string | null => {
  return localStorage.getItem("competitionId");
};


const API = {
  // UserController Endpoints
  getUserProfileName: async (): Promise<string> => {
    const response = await axios.get(`${BASE_URL}/users/getUserProfileName`, {
      headers: getHeaders(),
    });
    return response.data as string;
  },

  createOrUpdateTipsForUser: async (tips: any[]) => {
    const response = await axios.post(`${BASE_URL}/users/tips`, tips, {
      headers: getHeaders(),
    });
    return response.data;
  },

  getUserPoints: async () => {
    const competitionId = getCompetitionId();
    const response = await axios.get(
      `${BASE_URL}/users/points/${competitionId}`,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  },

  deleteUserAccount: async () => {
    const response = await axios.delete(`${BASE_URL}/users/delete`, {
      headers: getHeaders(),
    });
    return response.data;
  },

  getAllTipsForUser: async () => {
    const response = await axios.get(`${BASE_URL}/users/tips`, {
      headers: getHeaders(),
    });
    return response.data;
  },

  getHighscoreList: async (): Promise<UserWithPointsDTO[]> => {
    const competitionId = getCompetitionId();
    const response = await axios.get(
      `${BASE_URL}/users/highscore/${competitionId}`,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  },

  // ExternalAPIController Endpoints
  updateMatchResults: async () => {
    const response = await axios.post(
      `${BASE_URL}/ext/update/matches`,
      {},
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  },

  runNightJob: async () => {
    const response = await axios.post(
      `${BASE_URL}/ext/nightjob`,
      {},
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  },

  // MatchController Endpoints
  getAllMatches: async () => {
    const competitionId = getCompetitionId();
    const response = await axios.get(
      `${BASE_URL}/matches/${competitionId}`,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  },

  getTodayMatchesWithGroupTips: async (groupId: number) => {
    const competitionId = getCompetitionId();
    const response = await axios.get(`${BASE_URL}/matches/todaystips/${competitionId}?groupId=${groupId}`, {
      headers: getHeaders(),
    });
    return response.data;
  },

  // UserGroupController Endpoints
  createUserGroup: async (groupName: any, ) => {
    const competitionId = getCompetitionId();
    const response = await axios.post(
      `${BASE_URL}/usergroups/create/${competitionId}`,
      {groupName: groupName},
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  },

  getAllUserGroupsForCompetition: async (): Promise<UserGroup[]> => {
    const competitionId = getCompetitionId();
    const response = await axios.get(`${BASE_URL}/usergroups/${competitionId}`, {
      headers: getHeaders(),
    });
    return response.data;
  },

  getUserGroupsForUser: async () => {
    const competitionId = getCompetitionId();
    const response = await axios.get(
      `${BASE_URL}/usergroups/${competitionId}`,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  },

  getUsersInGroupSortedByPoints: async (groupId: number): Promise<UserWithPointsDTO[]> => {
    const response = await axios.get<UserWithPointsDTO[]>(
      `${BASE_URL}/usergroups/${groupId}/users`,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  },

  searchGroupByName: async (searchQuery: string): Promise<UserGroup[]> => {
    const competitionId = getCompetitionId();
    const response = await axios.get(
      `${BASE_URL}/usergroups/search/${competitionId}?name=${searchQuery}`,
      {
        headers: getHeaders()
            }
    );
    return response.data;
  },

  addUserToGroup: async (groupId: number) => {
    const response = await axios.post(
      `${BASE_URL}/usergroups/addUser`,
      { userGroupId: groupId },
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  },

  // UserGroupHistoryController Endpoints
  getTablePositionHistory: async (groupId: number) => {
    const response = await axios.get(`${BASE_URL}/history?userGroupId=${groupId}`, {
      headers: getHeaders(),
    });
    return response.data;
  },

  // TeamGroupController Endpoints
  getTeamsInTeamGroupSortedByPoints: async (teamGroupId: number) => {
    const response = await axios.get(
      `${BASE_URL}/teamgroups/${teamGroupId}/teams`,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  },

  // AuthController Endpoints
  googleLogin: async (authData: any) => {
    const response = await axios.post(
      `${BASE_URL}/auth/google`,
      { code: authData },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  },

    // Fetch all competitions
    getCompetitions: async () => {
      const response = await axios.get(`${BASE_URL}/competition`, {
        headers: getHeaders(),
      });
      return response.data as Competition[];
    },

    getLiveScores: async () => {
      const response = await axios.get(`${BASE_URL}/live-scores`, {
        headers: getHeaders(),
      });
      return response.data; // Om det behövs, annars kan du ta bort detta
    },
};

export default API;
