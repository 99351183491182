// src/App.tsx
import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import theme from "./theme";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";  // Importera Footer-komponenten
import Login from "./pages/Login";
import GroupPage from "./pages/groups/GroupPage";
import Highscore from "./pages/Highscore";
import FAQ from "./pages/FAQ";
import Scoreboard from "./pages/scoreboard/Scoreboard";
import ProtectedRoute from "./components/ProtectedRoute";
import { AuthProvider } from "./context/AuthContext";
import { GoogleOAuthProvider } from "@react-oauth/google";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <GoogleOAuthProvider clientId={CLIENT_ID!}>
          <Router>
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
              <Navbar />
              <Box sx={{ flexGrow: 1, paddingTop: '64px' }}> {/* Justera paddingTop efter Navbar */}
                <Routes>
                  <Route path="/" element={<Navigate to="/login" />} />
                  <Route path="/login" element={<Login />} />
                  <Route
                    path="/scoreboard"
                    element={<ProtectedRoute element={<Scoreboard />} />}
                  />
                  <Route
                    path="/grouppage"
                    element={<ProtectedRoute element={<GroupPage />} />}
                  /><Route
                  path="/groups"
                  element={<ProtectedRoute element={<GroupPage />} />}
                />
                  <Route
                    path="/highscore"
                    element={<ProtectedRoute element={<Highscore />} />}
                  />
                  <Route
                    path="/faq"
                    element={<ProtectedRoute element={<FAQ />} />}
                  />
                </Routes>
              </Box>
              <Footer /> {/* Lägg till Footer-komponenten */}
            </Box>
          </Router>
        </GoogleOAuthProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
