import React from "react";
import { Box, Modal, Typography, Avatar } from "@mui/material";
import { MatchDetailsProps } from "./ScoreBoardTypes";

const MatchDetails: React.FC<MatchDetailsProps> = ({
  open,
  handleClose,
  match,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 4,
        }}
      >
        <Typography variant="h6" component="h2">
          {match.homeTeam} vs {match.awayTeam}
        </Typography>
        <Box my={2} display="flex" justifyContent="center" alignItems="center">
          <Avatar
            src={match.homeTeamCrest || undefined}
            alt={match.homeTeam || ""}
            sx={{ mr: 2 }}
          />
          <Typography variant="h5">
            {match.realScoreHomeTeam} - {match.realScoreAwayTeam}
          </Typography>
          <Avatar
            src={match.awayTeamCrest || undefined}
            alt={match.awayTeam || ""}
            sx={{ ml: 2 }}
          />
        </Box>
        <Typography variant="body2" color="textSecondary">
          {new Date(match.matchDate + "Z").toLocaleString()}
        </Typography>
        {/* Add more match details here if necessary */}
      </Box>
    </Modal>
  );
};

export default MatchDetails;
