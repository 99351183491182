import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
} from "@mui/material";
import { TeamStats } from "./ScoreBoardTypes";

interface LeagueTableProps {
  teamStats: TeamStats[];
}

const LeagueTable: React.FC<LeagueTableProps> = ({ teamStats }) => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Team</TableCell>
              <TableCell>Played</TableCell>
              <TableCell>Points</TableCell>
              <TableCell>Wins</TableCell>
              <TableCell>Draws</TableCell>
              <TableCell>Losses</TableCell>
              <TableCell>GF</TableCell>
              <TableCell>GA</TableCell>
              <TableCell>GD</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teamStats.map((team) => (
              <TableRow key={team.teamName}>
                <TableCell>
                  <Avatar src={team.crest || undefined} />
                  {team.teamName}
                </TableCell>
                <TableCell>{team.gamesPlayed}</TableCell>
                <TableCell>{team.points}</TableCell>
                <TableCell>{team.wins}</TableCell>
                <TableCell>{team.draws}</TableCell>
                <TableCell>{team.losses}</TableCell>
                <TableCell>{team.goalsFor}</TableCell>
                <TableCell>{team.goalsAgainst}</TableCell>
                <TableCell>{team.goalDifference}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default LeagueTable;
