import React, { useEffect, useState } from "react";
import { Button, TablePagination } from "@mui/material";
import Match from "./Match";
import { calculateTeamStats } from "./ScoreBoardUtils";
import MatchListProps from "./MatchListProps";

const LIVE_SCORE_URL = process.env.REACT_APP_LIVE_SCORE_URL;

const MatchList: React.FC<MatchListProps> = ({
  matches,
  tips,
  leaguePage,
  rowsPerPage,
  setLeaguePage,
  setTips,
  setTeamStats,
  saveTips,
  saving,
}) => {
  const [liveScores, setLiveScores] = useState<{ matchId: number; realScoreHomeTeam: number; realScoreAwayTeam: number }[]>([]);

  useEffect(() => {
    if (!LIVE_SCORE_URL) {
      console.error("LIVE_SCORE_URL is not defined");
      return;
    }
    const eventSource = new EventSource(LIVE_SCORE_URL);

    eventSource.addEventListener("live-scores", (event: MessageEvent) => {
      const scoreUpdates = JSON.parse(event.data) as { matchId: number; realScoreHomeTeam: number; realScoreAwayTeam: number }[];
      setLiveScores(scoreUpdates);
    });

    return () => {
      eventSource.close();
    };
  }, []);

  const handleTipChange = (
    matchId: number,
    predictedScoreHomeTeam: number,
    predictedScoreAwayTeam: number
  ) => {
    const match = matches.find((m) => m.matchId === matchId);
    if (match && new Date(match.matchDate + "Z") <= new Date()) {
      return; // Prevent updating tips for matches that have already started
    }

    setTips((prevTips) => {
      const tipIndex = prevTips.findIndex((tip) => tip.matchId === matchId);
      let updatedTips;
      if (tipIndex >= 0) {
        updatedTips = [...prevTips];
        updatedTips[tipIndex] = {
          ...updatedTips[tipIndex],
          predictedScoreHomeTeam,
          predictedScoreAwayTeam,
        };
      } else {
        updatedTips = [
          ...prevTips,
          { matchId, predictedScoreHomeTeam, predictedScoreAwayTeam },
        ];
      }
      setTeamStats(calculateTeamStats(matches, updatedTips));
      return updatedTips;
    });
  };

  return (
    <>
      <TablePagination
        component="div"
        count={matches.length}
        page={leaguePage}
        onPageChange={(_, newPage) => setLeaguePage(newPage)}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
      />
      {matches
        .slice(leaguePage * rowsPerPage, (leaguePage + 1) * rowsPerPage)
        .map((match) => {
          const liveScore = liveScores.find(score => score.matchId === match.matchId);
          return (
            <Match
              key={match.matchId}
              match={match}
              tip={
                tips.find((tip) => tip.matchId === match.matchId) || {
                  matchId: match.matchId,
                  predictedScoreHomeTeam: 0,
                  predictedScoreAwayTeam: 0,
                }
              }
              onTipChange={handleTipChange}
              liveScore={liveScore} // Pass live score to Match
            />
          );
        })}
      <TablePagination
        component="div"
        count={matches.length}
        page={leaguePage}
        onPageChange={(_, newPage) => setLeaguePage(newPage)}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={saveTips}
        disabled={saving}
        fullWidth
      >
        {saving ? "Saving..." : "Save Tips"}
      </Button>
    </>
  );
};

export default MatchList;
