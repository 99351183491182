import { MatchData, Tip, TeamStats } from "./ScoreBoardTypes";

export const calculateTeamStats = (
  matches: MatchData[],
  tips: Tip[]
): TeamStats[] => {
  const teamStatsMap: { [key: string]: TeamStats } = {};

  matches.forEach((match) => {
    const {
      homeTeam,
      awayTeam,
      homeTeamCrest,
      awayTeamCrest,
      realScoreHomeTeam,
      realScoreAwayTeam,
      played,
    } = match;
    const tip = tips.find((tip) => tip.matchId === match.matchId);

    if (!homeTeam || !awayTeam) {
      return;
    }

    if (!teamStatsMap[homeTeam]) {
      teamStatsMap[homeTeam] = {
        teamName: homeTeam,
        crest: homeTeamCrest,
        points: 0,
        gamesPlayed: 0,
        wins: 0,
        draws: 0,
        losses: 0,
        goalsFor: 0,
        goalsAgainst: 0,
        goalDifference: 0,
      };
    }
    if (!teamStatsMap[awayTeam]) {
      teamStatsMap[awayTeam] = {
        teamName: awayTeam,
        crest: awayTeamCrest,
        points: 0,
        gamesPlayed: 0,
        wins: 0,
        draws: 0,
        losses: 0,
        goalsFor: 0,
        goalsAgainst: 0,
        goalDifference: 0,
      };
    }

    let predictedScoreHomeTeam = realScoreHomeTeam;
    let predictedScoreAwayTeam = realScoreAwayTeam;

    if (tip && !played) {
      predictedScoreHomeTeam = tip.predictedScoreHomeTeam;
      predictedScoreAwayTeam = tip.predictedScoreAwayTeam;
    }

    teamStatsMap[homeTeam].goalsFor += predictedScoreHomeTeam;
    teamStatsMap[homeTeam].goalsAgainst += predictedScoreAwayTeam;
    teamStatsMap[awayTeam].goalsFor += predictedScoreAwayTeam;
    teamStatsMap[awayTeam].goalsAgainst += predictedScoreHomeTeam;

    if (predictedScoreHomeTeam > predictedScoreAwayTeam) {
      teamStatsMap[homeTeam].points += 3;
      teamStatsMap[homeTeam].wins += 1;
      teamStatsMap[awayTeam].losses += 1;
    } else if (predictedScoreHomeTeam < predictedScoreAwayTeam) {
      teamStatsMap[awayTeam].points += 3;
      teamStatsMap[awayTeam].wins += 1;
      teamStatsMap[homeTeam].losses += 1;
    } else {
      teamStatsMap[homeTeam].points += 1;
      teamStatsMap[awayTeam].points += 1;
      teamStatsMap[homeTeam].draws += 1;
      teamStatsMap[awayTeam].draws += 1;
    }

    teamStatsMap[homeTeam].gamesPlayed += 1;
    teamStatsMap[awayTeam].gamesPlayed += 1;
    teamStatsMap[homeTeam].goalDifference =
      teamStatsMap[homeTeam].goalsFor - teamStatsMap[homeTeam].goalsAgainst;
    teamStatsMap[awayTeam].goalDifference =
      teamStatsMap[awayTeam].goalsFor - teamStatsMap[awayTeam].goalsAgainst;
  });

  return Object.values(teamStatsMap).sort((a, b) => {
    if (b.points !== a.points) {
      return b.points - a.points;
    }
    return b.goalDifference - a.goalDifference;
  });
};

export const findFirstPageWithMatchDate = (
  matches: MatchData[],
  date: Date,
  rowsPerPage: number
) => {
  const targetDate = new Date(date.toDateString()); // Normalize the date to remove time part
  const pages = Math.ceil(matches.length / rowsPerPage);

  for (let page = 0; page < pages; page++) {
    const pageMatches = matches.slice(
      page * rowsPerPage,
      (page + 1) * rowsPerPage
    );
    const hasMatchOnDate = pageMatches.some(
      (match) =>
        new Date(match.matchDate + "Z").toDateString() ===
        targetDate.toDateString()
    );

    if (hasMatchOnDate) {
      return page;
    }
  }

  // If no matches on the target date are found, return the first page with future matches
  for (let page = 0; page < pages; page++) {
    const pageMatches = matches.slice(
      page * rowsPerPage,
      (page + 1) * rowsPerPage
    );
    const hasFutureMatch = pageMatches.some(
      (match) => new Date(match.matchDate + "Z") > targetDate
    );

    if (hasFutureMatch) {
      return page;
    }
  }

  return 0;
};
