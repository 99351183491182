import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Box, Typography, IconButton, Pagination } from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import API from "../../components/API";
import { UserGroup } from "./GroupTypes";

const Container = styled("div")({
  padding: "24px",
});

const FormControl = styled("div")({
  marginBottom: "16px",
});

const ListItem = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "8px 0",
});

const ListItemText = styled(Typography)({
  flex: 1,
});

const JoinText = styled(Typography)({
  color: "gray",
  marginLeft: "0.5rem",
  textShadow: "1px 1px 2px rgba(0, 0, 0, 0.3)", // Skugga på texten
});

const Search: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchResult, setSearchResult] = useState<UserGroup[]>([]);
  const [allGroups, setAllGroups] = useState<UserGroup[]>([]);
  const [userGroups, setUserGroups] = useState<UserGroup[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const { token } = useAuth();
  const groupsPerPage = 10;

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        // Hämta alla grupper
        const allGroupsResponse = await API.getAllUserGroupsForCompetition();
        const sortedGroups = allGroupsResponse.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setAllGroups(sortedGroups);

        // Hämta användarens grupper
        const userGroupsResponse = await API.getUserGroupsForUser();
        setUserGroups(userGroupsResponse);

        // Ställ in antal sidor för paginering
        setTotalPages(Math.ceil(sortedGroups.length / groupsPerPage));
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };

    if (token) {
      fetchGroups();
    }
  }, [token]);

  const handleSearch = async () => {
    try {
      const competitionId = localStorage.getItem("competitionId");
      const response = await API.searchGroupByName(searchQuery);
      setSearchResult(response);
    } catch (error) {
      console.error(`Error searching for group ${searchQuery}:`, error);
      setSearchResult([]);
    }
  };

  const handleJoinGroup = async (groupId: number) => {
    try {
      API.addUserToGroup(groupId);
      alert("Successfully joined the group!");

      window.location.reload();
    } catch (error) {
      console.error(`Error joining group ${groupId}:`, error);
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const isUserInGroup = (groupId: number) => {
    return userGroups.some((group) => group.id === groupId);
  };

  const paginatedGroups = allGroups.slice(
    (page - 1) * groupsPerPage,
    page * groupsPerPage
  );

  return (
    <Container>
      <FormControl>
        <TextField
          label="Search for a group"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          variant="outlined"
          fullWidth
          style={{ marginBottom: "1rem" }}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSearch}>
          Search
        </Button>
      </FormControl>

      {searchResult.length > 0 && (
        <Box
          sx={{
            border: "1px solid #ddd",
            borderRadius: "8px",
            padding: "16px",
            marginBottom: "1rem",
          }}>
          <Typography variant="h6" sx={{ marginBottom: "1rem" }}>
            Found groups:
          </Typography>
          {searchResult.map((group) => (
            <ListItem key={group.id}>
              <ListItemText variant="body1">{group.name}</ListItemText>
              {!isUserInGroup(group.id) && (
                <IconButton
                  color="primary"
                  onClick={() => handleJoinGroup(group.id)}>
                  <JoinText variant="body2">Join group</JoinText>
                </IconButton>
              )}
            </ListItem>
          ))}
        </Box>
      )}

      <Box
        sx={{ border: "1px solid #ddd", borderRadius: "8px", padding: "16px" }}>
        <Typography variant="h6" sx={{ marginBottom: "1rem" }}>
          All Groups:
        </Typography>
        {paginatedGroups.map((group) => (
          <ListItem key={group.id}>
            <ListItemText variant="body1">{group.name}</ListItemText>
            {!isUserInGroup(group.id) && (
              <IconButton
                color="primary"
                onClick={() => handleJoinGroup(group.id)}>
                <JoinText variant="body2">Join group</JoinText>
              </IconButton>
            )}
          </ListItem>
        ))}

        <Box display="flex" justifyContent="center" marginTop="1rem">
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default Search;
