import React from 'react';
import { Box } from '@mui/material'; // eller den komponent som passar din setup
import {
  HighscoreContainer,
  HighscoreItem,
  HighscoreName,
  HighscorePoints,
  TipsContainer,
  colorPalette,
} from './StyledComponents'; // Anpassa sökvägen efter var du har dina stylade komponenter
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts'; // Justera importerna enligt dina behov
import { UserWithPointsDTO } from './GroupTypes';



interface HighscoreListProps {
  groupUsers: UserWithPointsDTO[];
  handleUserClick: (user: UserWithPointsDTO) => void;
  formatDataForChart: () => any[]; // Ändra till rätt typ för din data
  visibleLines: string[];
  handleLegendClick: (user: { value: string }) => void;
}

const HighscoreList: React.FC<HighscoreListProps> = ({
  groupUsers,
  handleUserClick,
  formatDataForChart,
  visibleLines,
  handleLegendClick,
}) => {
  return (
    <HighscoreContainer>
      {groupUsers.map((user) => (
        <HighscoreItem key={user.email} onClick={() => handleUserClick(user)}>
          <HighscoreName>{user.name}</HighscoreName>
          <HighscorePoints>{user.points} points</HighscorePoints>
        </HighscoreItem>
      ))}

      <TipsContainer>
        <ResponsiveContainer width="100%" height={500}>
          <LineChart
            data={formatDataForChart()}
            margin={{ top: 5, right: 20, left: 10, bottom: 50 }} // Öka bottenmarginalen för att få plats med användarnamn
          >
            <CartesianGrid stroke="#ccc" />
            <XAxis
              dataKey="matchNumber"
              label={{
                value: "Match Number",
                position: "insideBottomRight",
                offset: -5,
              }}
            />
            <YAxis />
            <Tooltip />
            {groupUsers.map((user, index) => (
              <Line
                key={user.email}
                type="monotone"
                dataKey={user.email}
                name={user.name}
                stroke={colorPalette[index % colorPalette.length]}
                strokeWidth={4}
                dot={false}
                isAnimationActive={true}
                hide={!visibleLines.includes(user.email)}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>

        {/* Lista med klickbara användarnamn */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            marginTop: 2,
          }}
        >
          {groupUsers.map((user, index) => (
            <Box
              key={user.email}
              sx={{
                padding: "8px",
                cursor: "pointer",
                color: visibleLines.includes(user.email)
                  ? colorPalette[index % colorPalette.length]
                  : "#aaa",
                textDecoration: "underline",
              }}
              onClick={() => handleLegendClick({ value: user.email })}
            >
              {user.name}
            </Box>
          ))}
        </Box>
      </TipsContainer>
    </HighscoreContainer>
  );
};

export default HighscoreList;
