import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTheme } from "@mui/material/styles"; 
import { useAuth } from "../context/AuthContext";
import API from "../components/API";

interface Competition {
  id: number;
  name: string;
  emblem: string;
}

const Navbar: React.FC = () => {
  const { token, setToken } = useAuth();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedCompetition, setSelectedCompetition] = useState<string>("");
  const [competitions, setCompetitions] = useState<Competition[]>([]); // Lista med tävlingar
  const [competitionLogo, setCompetitionLogo] = useState<string | null>(null); // Tillstånd för tävlingslogotypen

  const theme = useTheme();

  useEffect(() => {
    const fetchCompetitions = async () => {
      try {
        const data = await API.getCompetitions();
        setCompetitions(data); // Sätt tävlingarna i state
        const competitionId = localStorage.getItem("competitionId");

        // Om det finns en sparad tävling, visa den i navbaren
        const selected = data.find(comp => comp.id.toString() === competitionId);
        if (selected) {
          setSelectedCompetition(selected.name);
          setCompetitionLogo(selected.emblem);
        }
      } catch (error) {
        console.error("Error fetching competitions:", error);
      }
    };

    const getUserProfileName = async () => {
      try {
        const data = await API.getUserProfileName();
        localStorage.setItem("userName", data);
      } catch (error) {
        console.error("Error fetching user name:", error);
      }
    };

    fetchCompetitions();
    getUserProfileName();
  }, []);

  const handleDrawerOpen = () => setDrawerOpen(true);
  const handleDrawerClose = () => setDrawerOpen(false);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handleIconMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => setMenuAnchorEl(event.currentTarget);
  const handleIconMenuClose = () => setMenuAnchorEl(null);

  const handleLogout = () => {
    setToken(null);
    localStorage.clear();
    navigate("/login");
    handleMenuClose();
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
    handleMenuClose();
  };
  const handleCloseDialog = () => setOpenDialog(false);
  const handleDeleteAccount = async () => {
    try {
      await API.deleteUserAccount();
      setToken(null);
      localStorage.clear();
      navigate("/login");
    } catch (error) {
      console.error("Error deleting account:", error);
    } finally {
      setOpenDialog(false);
    }
  };
  const handleCompetitionClick = (id: number, name: string, logoUrl: string) => {
    localStorage.setItem("competitionId", id.toString());
    setSelectedCompetition(name);
    setCompetitionLogo(logoUrl);
    navigate("/scoreboard");
    window.location.reload();
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      }}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerOpen}
        >
          <MenuIcon />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />

        {/* Visa vald tävlings logotyp i Navbar */}
        {token && competitionLogo && (
          <Box display="flex" alignItems="center" mr={2}>
            <img
              src={competitionLogo}
              alt={selectedCompetition}
              style={{ height: 30, width: 30, marginRight: 10 }}
            />
            <Button
              color="inherit"
              onClick={handleIconMenuClick}
              endIcon={<ArrowDropDownIcon />}
            >
              {selectedCompetition}
            </Button>
          </Box>
        )}

        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleIconMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {competitions.map((competition) => (
            <MenuItem
              key={competition.id}
              onClick={() => handleCompetitionClick(competition.id, competition.name, competition.emblem)}
            >
              <img
                src={competition.emblem}
                alt={competition.name}
                style={{ height: 30, width: 30, marginRight: 10 }}
              />
              {competition.name}
            </MenuItem>
          ))}
        </Menu>

        <Box sx={{ flexGrow: 1 }} />

        {token ? (
  <>
    <Button color="inherit" onClick={handleMenuClick}>
      {localStorage.getItem("userName") || "Account"}
    </Button>
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
      <MenuItem onClick={handleOpenDialog}>Delete Account</MenuItem>
    </Menu>

    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>Confirm Account Deletion</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete your account? This action cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDeleteAccount} color="secondary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  </>
) : (
  <Button color="inherit" component={Link} to="/login">
    Login
  </Button>
)}

      </Toolbar>

      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={handleDrawerClose}
          onKeyDown={handleDrawerClose}
        >
          <List>
            {token ? (
              <>
                <ListItem disablePadding>
                  <ListItemButton component={Link} to="/scoreboard">
                    <ListItemText primary="Scoreboard" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component={Link} to="/grouppage">
                    <ListItemText primary="Groups" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component={Link} to="/highscore">
                    <ListItemText primary="Highscore" />
                  </ListItemButton>
                </ListItem>
              </>
            ) : (
              <ListItem disablePadding>
                <ListItemButton component={Link} to="/login">
                  <ListItemText primary="Login" />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default Navbar;
