import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import API from "../components/API";

interface UserWithPointsDTO {
  name: string;
  email: string;
  points: number;
  competitionId: number;
}

const Container = styled("div")({
  padding: "24px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});

const HighscoreContainer = styled(Paper)({
  padding: "16px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
  width: "100%",
  maxWidth: "600px",
});

const StyledTableCell = styled(TableCell)({
  fontWeight: "bold",
});

const Highscore: React.FC = () => {
  const [highScore, setHighScore] = useState<UserWithPointsDTO[]>([]);

  useEffect(() => {
    const fetchHighScore = async () => {
      try {
        const response = await API.getHighscoreList();
        setHighScore(response);
      } catch (error) {
        console.error("Error fetching highscore:", error);
      }
    };

    fetchHighScore();
  }, []);

  return (
    <Container>
      <HighscoreContainer elevation={3}>
        <Typography variant="h5" gutterBottom>
          Highscore List
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Position</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell align="right">Points</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {highScore.map((user, index) => (
              <TableRow key={user.email}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell align="right">{user.points}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </HighscoreContainer>
    </Container>
  );
};

export default Highscore;
