import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import Groups from "./Groups";
import CreateGroup from "./CreateGroup";
import Search from "./Search";

const GroupPage: React.FC = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleTabSwipe = (index: number) => {
    setTabIndex(index);
  };

  return (
    <Box>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Groups" />
        <Tab label="Create" />
        <Tab label="Search" />
      </Tabs>
      <SwipeableViews index={tabIndex} onChangeIndex={handleTabSwipe}>
        <Box p={3}>
          <Groups />
        </Box>
        <Box p={3}>
          <CreateGroup />
        </Box>
        <Box p={3}>
          <Search />
        </Box>
      </SwipeableViews>
    </Box>
  );
};

export default GroupPage;
